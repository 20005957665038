<template>
  <van-tabbar v-model="active" active-color="#ee0a24">
    <van-tabbar-item icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item icon="cart-o">抢购</van-tabbar-item>
    <van-tabbar-item icon="friends-o">我的</van-tabbar-item>
  </van-tabbar>
</template>
<script setup>
import { ref,watch,onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
let active = ref(0);
watch(active,(newVal,oldVal)=>{
  console.log(active.value);
  sessionStorage.setItem("navActive",newVal);
  switch (newVal) {
    case 0:
    router.push({path:"/home"})
      break;
    case 1:
    router.push({path:"panic_buying"})
      break;
    case 2:
    router.push({path:"/me"})
      break;  
    default:
      break;
  }
});
onMounted(()=>{
  if(sessionStorage.getItem("navActive")!=""){
    active.value = Number(sessionStorage.getItem("navActive"));
  }
})
</script>
<style scoped lang='less'>
</style>